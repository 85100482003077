import "./bootstrap";
import "./common";
import "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-json";
import {resizeFonts} from "./font.js";

document.addEventListener("DOMContentLoaded", (event) => {
    Prism.highlightAll();
});

resizeFonts();
